
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid, IonLoading,
    IonPage,
    IonRow,
    IonTextarea,
    modalController,
} from '@ionic/vue';
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import {currentContest} from "@/util/ContestService";
import VerifyRating from "@/components/VerifyRating.vue";
import {Cheese} from "@/util/CheeseService";
import {fetchResultPoints, ResultPoints} from '@/util/ResultService';
import {isLoading, setLoadingState} from "@/util/LocalStateService";


export default defineComponent({
    name: "Rating",
    components: {
        Header, IonPage, IonGrid, IonRow, IonCol, IonContent,
        IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton,
        IonTextarea, IonLoading
    },

    data() {
        return {
            header: true as boolean,
            disabled: true as boolean,
            comment: '' as string | undefined,
            error: {
                show: false,
                points1: {lable: 'Aussehen', value: false},
                points2: {lable: 'Teig/Konsistenz', value: false},
                points3: {lable: 'Geruch', value: false},
                points4: {lable: 'Geschmack', value: false},
                comment: {lable: 'Kommentar', value: false},
            }
        }
    },

    computed: {
        getContest(){
            if(currentContest.value){
                return currentContest.value
            }else{
                return false
            }
        },
        isCurrentlyLoading(): boolean {
            return isLoading.value
        },

        getCheeseInfos(): Cheese | null {
            if (this.$route.params.cheeseID) {
                if (currentContest.value) {
                    return currentContest.value.getParticipant(Number(this.$route.params.cheeseID))
                }
            }
            return null
        },

        getTableId() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get('tableID')
        },
        getOrigin() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get('origin')
        }
    },
    methods: {
        back(): void {
            this.init()
        },

        initErrors(): void{
            this.error.points1.value = false
            this.error.points2.value = false
            this.error.points3.value = false
            this.error.points4.value = false
            this.error.comment.value = false
            this.error.show = false
        },

        async showVerifyModal(): Promise<any> {
            this.isDisabled()
            if(!this.disabled) {
                this.initErrors()
                const modal = await modalController
                    .create({
                        component: VerifyRating,
                        componentProps: {
                            points: this.getCheeseInfos?.resultPoints,
                            tableId: this.getTableId,
                            origin: this.getOrigin
                        },
                        cssClass: 'custommodal'
                    })
                return modal.present();
            }
        },

        setVote(vote: number, index: number) {
            const voteElem = this.$refs['rating' + vote] as HTMLElement;
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
            setLoadingState(true);

            if (!points) {
                this.getCheeseInfos?.createResultPoints(('points' + vote), (index === 1 ? 0 : index + 3))
            } else {
                points[('points' + vote)] = index === 1 ? 0 : index + 3;
                points.save()
            }

            Array.from(voteElem.children).forEach(elem => {
                elem.classList.remove('active')
            });
            voteElem.children[index - 1].classList.add('active');
            this.initErrors()
        },

        setComment(event: any): void {
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)

            setLoadingState(true);

            if (!points) {
                this.getCheeseInfos?.createResultPoints('comment', event.target?.defaultValue)
            } else {
                points.comment = event.target?.defaultValue;
                points.save()
            }

            this.initErrors()
        },

        isDisabled(): void {
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
            if (points) {
                if(points.points1 === undefined){this.error.points1.value = true; this.error.show = true;}
                if(points.points2 === undefined){this.error.points2.value = true; this.error.show = true;}
                if(points.points3 === undefined){this.error.points3.value = true; this.error.show = true;}
                if(points.points4 === undefined){this.error.points4.value = true; this.error.show = true;}
                if(points.comment === undefined){this.error.comment.value = true; this.error.show = true;}
                this.disabled = !(points.points1 !== undefined && points.points2 !== undefined && points.points3 !== undefined && points.points4 !== undefined && points.comment !== undefined);

            }
        },

        init() {
            fetchResultPoints().then(() => {
                const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
                if (points) {
                    const voteElem = document.querySelectorAll('.vote-container')
                    Array.from(voteElem).forEach((elem, index) => {
                        const p = points['points' + (index + 1)];
                        if (p !== null && p !== undefined) {
                            const pn = Number(points['points' + (index + 1)]);
                            if(pn === 0) {
                                elem.children[Number(points['points' + (index + 1)])].classList.add('active')
                            } else {
                                elem.children[Number(points['points' + (index + 1)]) - 4].classList.add('active')
                            }
                        }
                    });
                    this.comment = points.comment
                }
            })
        }
    },

    watch: {
        getContest: {
            immediate: true,
            handler(newVal, oldVal) {
                if(newVal){
                    if (!this.$route.params.cheeseID || isNaN(Number(this.$route.params.cheeseID))) {
                        this.$router.replace('/')
                    }
                        this.init()
                }
            },
        },
    },
})
